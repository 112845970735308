import { HtmlText } from '@landing-blocks/shared/components/html-text/html-text.js';
import { MultilineText } from '@landing-blocks/shared/components/multiline-text/multiline-text.js';
import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockProfessions1 } from './block-professions-1.constants.js';

import './block-professions-1.css';

import './__head/block-professions-1__head.css';
import './__professions/block-professions-1__professions.css';
import './__subtitle/block-professions-1__subtitle.css';
import './__title/block-professions-1__title.css';

type BlockAttributes = {
    title: string;
    subtitle: string;
    children: ComponentChildren;
};

export interface BlockProfessions1Props extends BlockAttributes {}

export const BlockProfessions1: FunctionalComponent<BlockProfessions1Props> = ({ title, subtitle, children }) => {
    return (
        <div className={cnBlockProfessions1({})}>
            <div className={cnBlockProfessions1('head')}>
                <MultilineText className={cnBlockProfessions1('title')}>
                    <HtmlText html={title} />
                </MultilineText>
                <MultilineText className={cnBlockProfessions1('subtitle')}>
                    <HtmlText html={subtitle} />
                </MultilineText>
            </div>
            <div className={cnBlockProfessions1('professions')}>{children}</div>
        </div>
    );
};
